import request from '@/router/axios';
import { datalogApiBase, pvhApiBase } from "@/config/env"

export const GetListDataType = () => {
    return request({
        url: datalogApiBase + "Log/GetListDataType",
        method: "get"
    })
}

export const GetListDataLogInfosByType = (type) => {
    return request({
        url: datalogApiBase + "Log/GetListDataLogInfosByType",
        method: "get",
        params: {
            "dataType": type
        }
    })
}


export const GetDataLogInfos = (dataType, beginTime, endTime, page, pageSize) => {
    return request({
        url: datalogApiBase + "Log/GetDataLogInfos",
        method: "get",
        params: {
            "dataType": dataType,
            "beginTime": beginTime,
            "endTime": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const GetDataLogInfo = (data) => {
    return request({
        url: datalogApiBase + "Log/GetDataLogInfo",
        method: "get",
        params: data
    })
}

export const PostDataLog = (data) => {
    return request({
        url: datalogApiBase + "Log/AddDataLogInfo",
        method: "post",
        data: {
             ...data
        } 
    })
}

export const GetDataInterfaceByDataLog = (datalogid) => {
    return request({
        url: datalogApiBase + "Log/GetDataInterfaceByDataLog",
        method: "get",
        params: {
            "datalogid": datalogid
        }
    })
}

export const GetDataInterface = (id) => {
    return request({
        url: datalogApiBase + "Log/GetDataInterface",
        method: "get",
        params: {
            "id": id
        }
    })
}

export const PostDataInterface = (data) => {
    return request({
        url: datalogApiBase + "Log/PostDataInterface",
        method: "post",
        data: {
            ...data
        }
    })
}


export const GetDataImportInfos = (datalogid, po, page, pageSize) => {
    return request({
        url: datalogApiBase + "Log/GetDataImportInfos",
        method: "get",
        params: {
            "datalogid": datalogid,
            "po": po,
            "page": page,
            "pageSize": pageSize
        }
    })
}

export const GetListDataImportsByDataLog = (datalogid) => {
    return request({
        url: datalogApiBase + "Log/GetListDataImportsByDataLog",
        method: "get",
        params: {
            "datalogid": datalogid
        }
    })
}

export const PostDataImport = (data) => {
    return request({
        url: datalogApiBase + "Log/PostDataImport",
        method: "post",
        data: {
            ...data
        }
    })
}

export const PostBatchDataImport = (data) => {
    return request({
        url: datalogApiBase + "Log/PostBatchDataImport ",
        method: "post",
        data: data
    })
}
